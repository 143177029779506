import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { HttpHelpUtils } from './../../utils/HttpHelpUtils';
import { Observable, of, EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BtnAuthService implements Resolve<any>{

  constructor(
    private http: HttpHelpUtils,
    private router: Router
  ) { }

  public routeAuths = [];

    async getRouteAuths(routeUrl: string) {
        if (!routeUrl) {
            // this.http.message.add({ key: 'tc', severity: 'error', summary: 'Error', detail: '页面信息丢失' })
            return
        }

        if (routeUrl.substring(0, 8) == 'shipper/') {
            routeUrl = routeUrl.replace('shipper/', '');
        } else if (routeUrl.substring(0, 6) == 'agent/') {
            routeUrl = routeUrl.replace('agent/', '');
        }
        const res = await this.http.get('sys/menu/get-route-auths', { url: routeUrl, sysType: 1 })
        if (res) {
            this.routeAuths = res['data'] || [];
            return true
        } else {
            return false
        }
    }

    async resolve(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<Observable<never> | Observable<any>> {
        const url = state.url.substr(0, 1) == '/' ? state.url.replace('/', '') : state.url
        const res = await this.getRouteAuths(url)
        if (res) {
            return of(res)
        } else {
            this.router.navigate([this.router.url]);
            return EMPTY;
        }
    }

  checkBtnAuth(button_id) {
    const hasAuths = this.routeAuths.filter(item =>{
      return item['button_id'] == button_id
    })|| []
    // if (hasAuths.length == 0) {
    //   this.http.message.add({key: 'tc', severity: 'error', summary: 'Error', detail: '您没有操作权限，若要执行此操作，请联系管理员' })
    // }

    return hasAuths.length > 0

  }
}
