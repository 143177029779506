import {
    trigger, sequence, transition, animate, style, query, stagger, state, animation
} from '@angular/animations';

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('* <=> *', [
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: '0',
                width: '100%',
                opacity: 1,
                transform: 'scale(0) translateY(-50%)',
            }),
        ], { optional: true }),
        sequence([
            query(':enter', [
                animate('600ms ease', style({ opacity: 1, transform: 'scale(1) translateY(0%)' })),
            ], {optional: true})
        ])
    ])
]);

export const emptyDataTrigger = 
  trigger('emptyData', [
    state('true, false', style({
      opacity: 1
    })),
    transition('true <=> false', [
      query('.emptyImg', 
        [style({transform: 'rotate(0)'}),
        animate('0.1s', style({ transform: 'rotate(5deg)', width: '320px', height: '320px' })),
        animate('0.1s', style({ transform: 'rotate(-5deg)', width: '320px', height: '320px'  })),
        animate('0.1s', style({ transform: 'rotate(5deg)', width: '320px', height: '320px'  })),
        animate('0.1s', style({ transform: 'rotate(0)' }))
      ], { optional: true }),
    ])
]);

export const filterTrigger =
  trigger('filterAnimation', [
    transition(':enter, * => 0, * => -1', []),
    transition(':increment', [
      query(':enter', [
        style({ opacity: 0 }),
        stagger(50, [
          // animate('100ms', style({ opacity: 1 }))
          animate('100ms ease-out', style({ opacity: 1, width:'*' })),
        ]),
      ], { optional: true })
    ]),
    transition(':decrement', [
      query(':leave', [
        stagger(50, [
          animate('100ms ease-out', style({ opacity: 0, width: 0 })),
        ]),
      ], { optional: true })
    ]),
]);

export const serachTrigger =
  trigger('esSearch', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateY(10px)' }),
      animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
      // style({ opacity: 0,height: 0 }),
      // query(':enter', [
      //   animate('1.5s ease-out', style({ opacity: 1, height: '-webkit-fill-available' })),
      // ], { optional: true } )
    ]),
    transition(':leave', [
      animate('700ms ease-out', style({ opacity: 0, height: '0px' }))
    ])
]);

export const changeTabTrigger = 
    trigger('changeTab', [
        transition(':enter',
            animation([
                style({
                    opacity: 0,
                    position: 'absolute',
                    top: 0,
                    left: '100%',
                    width: '100%',
                }),
                animate(
                    '0.5s',
                    style({
                        opacity: 1,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                    })
                ),
            ])
        ),
        transition(':leave', 
            animation([
                style({ 
                    opacity: 1,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                 }),
                animate(
                    '0.5s',
                    style({
                        position: 'absolute',
                        top: 0,
                        left: '-100%',
                        opacity: 0,
                        width: 0
                    })
                ),
            ])
        )
    ]);

