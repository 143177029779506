import { Injectable, OnDestroy, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class ReuseStrategyTab implements OnDestroy {
    cacheRouters: { [key: string]: any } = {};

    private cachedChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    /** 缓存路由列表 */
    private reuseTabCachedList: any[] = [];

    private titleCached: { [url: string]: string } = {};
    private removeUrlBuffer: string;

    /** 当前路由地址 */
    get curUrl() {
        return this.getUrl(this.injector.get(ActivatedRoute).snapshot);
    }
    /** 获取已缓存的路由 */
    get reuseTabList(): any[] {
        return this.reuseTabCachedList;
    }

    /** 获取当前缓存的路由总数 */
    get count() {
        return this.reuseTabCachedList.length;
    }
    /** 订阅缓存变更通知 */
    get change(): Observable<any> {
        return this.cachedChange.asObservable().pipe(filter(w => w !== null));
    }
    constructor(private injector: Injector) { }

    /**
     * 关闭tab
     * @param url 路由url
     * @param includeNonCloseable 是否包含强制不可关闭
     */
    close(url: string, includeNonCloseable = false): any {
        this.removeUrlBuffer = url;
        this.remove(url, includeNonCloseable);
        this.cachedChange.next({ active: 'close', url, list: this.reuseTabCachedList });
        this.di('close tag', url);
        return true;
    }

    /**
     * 决定是否允许路由复用，若 `true` 会触发 `store`
     */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        if (this.hasInValidRoute(route)) {
            return false;
        }
        return this.can(route);
    }

    /**
     * 存储快照
     */
    store(snapshot: ActivatedRouteSnapshot, handle: any) {
        const url = this.getUrl(snapshot);
        const index = this.index(url);
        const title = this.getTitle(url, snapshot);
        const item = {
            title: title,
            closable: true,
            url: url,
            _snapshot: snapshot,
            _handle: handle,
        };
        if (index === -1) {
            this.reuseTabCachedList.push(item);
        } else {
            this.reuseTabCachedList[index] = item;
        }
        this.removeUrlBuffer = null;
        this.di('#store', index === -1 ? '[new]' : '[override]', url);
        this.cachedChange.next({ active: 'add', item, list: this.reuseTabCachedList });
    }
    /**
     * 决定是否允许应用缓存数据
     */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        if (this.hasInValidRoute(route)) {
            return false;
        }
        const url = this.getUrl(route);
        const data = this.getReuseTabCached(url);
        const ret = !!(data && data._handle);
        this.di('#shouldAttach', ret, url);
        // if (ret && data._handle.componentRef) {
        //     this.runHook('_onReuseInit', url, data._handle.componentRef);
        // }
        return ret;
    }
    /**
     * 提取复用数据
     */
    retrieve(route: ActivatedRouteSnapshot): {} {
        if (this.hasInValidRoute(route)) {
            return null;
        }
        const url = this.getUrl(route);
        const data = this.getReuseTabCached(url);
        const ret = (data && data._handle) || null;
        this.di('#retrieve', url, ret);
        return ret;
    }
    /**
     * 决定是否应该进行复用路由处理
     */
    shouldReuseRoute(
        future: ActivatedRouteSnapshot,
        curr: ActivatedRouteSnapshot,
    ): boolean {
        let ret = future.routeConfig === curr.routeConfig;
        if (!ret) {
            return false;
        }
        const path = ((future.routeConfig && future.routeConfig.path) || '') as string;
        if (path.length > 0 && ~path.indexOf(':') ) {
            const futureUrl = this.getUrl(future);
            const currUrl = this.getUrl(curr);
            ret = futureUrl === currUrl;
        }
        this.di('=====================');
        this.di('#shouldReuseRoute', ret, `${this.getUrl(curr)}=>${this.getUrl(future)}`, future, curr);
        return ret;
    }

    /** 获取指定路径缓存所在位置，`-1` 表示无缓存 */
    index(url: string): number {
        return this.reuseTabCachedList.findIndex(w => w.url === url);
    }
    /** 获取指定路径缓存是否存在 */
    exists(url: string): boolean {
        return this.index(url) !== -1;
    }
    /** 获取指定路径缓存 */
    getReuseTabCached(url: string): any {
        return url ? this.reuseTabCachedList.find(w => w.url === url) || null : null;
    }
    /**
     * 获取标题
     * @param url 指定URL
     * @param route 指定路由快照
     */
    getTitle(url: string, route?: ActivatedRouteSnapshot): string {
        if (this.titleCached[url]) {
            return this.titleCached[url];
        }
        if (route && route.data && route.data.title) {
            return route.data.title;
        }
    }
    /**
     * 清除标题缓存
     */
    clearTitleCached() {
        this.titleCached = {};
    }
    getTruthRoute(route: ActivatedRouteSnapshot) {
        let next = route;
        while (next.firstChild) {
            next = next.firstChild;
        }
        return next;
    }
    /**
     * 根据快照获取URL地址
     */
    getUrl(route: ActivatedRouteSnapshot): string {
        let next = this.getTruthRoute(route);
        const segments = [];
        while (next) {
            segments.push(next.url.join('/'));
            next = next.parent;
        }
        const url =
            '/' +
            segments
                .filter(i => i)
                .reverse()
                .join('/');
        return url;
    }


    /**
     * 运行生命周期钩子
     * @param method 
     * @param url 
     * @param comp 
     */
    private runHook(method: string, url: string, comp: any) {
        if (comp.instance && typeof comp.instance[method] === 'function') {
            comp.instance[method]();
        }
    }

    /**
     * 组件销毁
     * @param _handle
     */
    private destroy(handle: any) {
        if (handle && handle.componentRef && handle.componentRef.destroy) {
            handle.componentRef.destroy();
        }
    }

    /**
     * 移除url
     * @param url 
     * @param includeNonCloseable 
     */
    private remove(url: string | number, includeNonCloseable: boolean): boolean {
        const idx = typeof url === 'string' ? this.index(url) : url;
        const item = idx !== -1 ? this.reuseTabCachedList[idx] : null;
        if (!item || (!includeNonCloseable && !item.closable)) {
            return false;
        }
        this.destroy(item._handle);

        this.reuseTabCachedList.splice(idx, 1);
        delete this.titleCached[url];
        return true;
    }

    /**
     * 去掉loadChildren，以及children
     * 得到纯路由
     * @param route 
     */
    private hasInValidRoute(route: ActivatedRouteSnapshot) {
        return (
            !route.routeConfig ||
            route.routeConfig.loadChildren ||
            route.routeConfig.children
        );
    }
    /**
     * 检查快照是否允许被复用
     */
    private can(route: ActivatedRouteSnapshot): boolean {
        const url = this.getUrl(route);
        if (url === this.removeUrlBuffer || url.indexOf('zdrw') !== -1 || url.indexOf('cl')) {
            return false;
        }
        if (route.data && typeof route.data.reuse === 'boolean') {
            return route.data.reuse;
        }
        return true;
    }

    private di(...args ) {
        // tslint:disable-next-line:no-console
    }

    ngOnDestroy(): void {
        this.reuseTabCachedList = [];
        this.cachedChange.unsubscribe();
    }
}