import { Observable, of, Subject } from 'rxjs';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionStorage } from './SessionStorage';
import { HttpHelpUtils } from './HttpHelpUtils';
import { environment } from './../../environments/environment';
import { CusRouteReuseStrategy } from './CusRouteReuseStrategy';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private storage: SessionStorage,
        private httpHelp: HttpHelpUtils) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const that = this;
        if (request.headers.has('upload')) {
            return next.handle(request.clone({
                headers: new HttpHeaders().
                    set('Access-Control-Allow-Headers', 'Content-Type').
                    set('Access-Control-Allow-Methods', 'GET,POST,PUT').
                    set('Access-Control-Allow-Origin', '*')
                })).pipe(
                tap(
                    event => {
                        if (event['status'] == 200 && event['statusText'] == 'OK') {
                            event['body'] = {
                                status: 200,
                                statusText: 'OK'
                            };
                        }
                    },
                    error => that.handleError(error)
                ),
                finalize(() => { 

                })
            );
        } else {
            return next.handle(request.clone({
                headers: new HttpHeaders().
                    set('token', this.storage.getItem(environment.tokenKey))
            })).pipe(
                tap(
                event => (event instanceof HttpResponse ? 'succeeded' : ''),
                error => that.handleError(error)
                ),
                finalize(() => { })
            );
        }
        
    }

    // error req handle
    protected handleError(error: HttpErrorResponse) {
        const that = this;
        that.httpHelp.loadingBlock = false;
        switch (error.status) {
            case 401:
                CusRouteReuseStrategy.handlers = {};
                that.router.navigateByUrl('/login');
                break;
            case 403:
                break;
            case 404:
                break;
            case 408:
                CusRouteReuseStrategy.handlers = {};
                that.router.navigateByUrl('/login');
                break;
            case 504:
                break;
            default:
                break;
        }
    }
}
