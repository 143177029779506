<div class="layout-wrapper layout-menu-dark layout-wrapper-static p-d-flex"
    style="justify-content: center;align-items: center;height: 100vh;" *ngIf="!checkLoginStatus" @inOutPaneAnimation>
    <img [src]="httpHelp.loadingGif" style="width: 160px;height:160px;" />
</div>
<div class="layout-container p-flex" *ngIf="checkLoginStatus" @showPaneAnimation>
    <div class="layout-sidebar" [style]="{width: menuWidth }">
        <div class="sidebar-logo">
            <a routerlink="/">
                <img *ngIf="menuWidth == '240px'" src="assets/file/image/pclogo.png" class="pclogo" />
                <img *ngIf="menuWidth == '64px'" class="pclogo" src="assets/file/image/pcLogo64.png" />
            </a>
        </div>
        <div #userOperate class="side-user" (click)="showUserInfo($event)" *ngIf="this.headerSetType == 'hidden' && menuWidth == '240px'" >
            <span>
                <img src="assets/file/image/user.png" alt="mirage-layout" style="width:38px;height:38px;">
            </span>
            <span style="margin-left: 15px;color: var(--text-color);">
                <h4 style="width:80px;margin:0;font-size:15px;font-weight: 600;overflow: hidden;height:19px;text-overflow: ellipsis;">
                    {{ loginData['realname'] }}
                </h4>
                <div style="width:80px;font-size:13px;overflow: hidden;height:19px;text-overflow: ellipsis;margin-left:2px;">
                    {{ loginData['companyName'] }}
                </div>
            </span>
            <div *ngIf="toggleUserInfo" [@overlayMenuAnimation]="'visible'"
                style="position:absolute;top:100%;left:30px;z-index: 200;border-radius: 3px;box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);">
                <div pRipple class="operate-list" (click)="queryUserInfo()"
                    style="border-bottom: 1px solid #DEE1E6;">
                    <i class="pi pi-user p-mr-3 p-ml-2" style="font-size:20px;"></i>
                    <div>个人信息</div>
                    <i class="pi pi-angle-right" style="margin-left:35px"></i>
                </div>
                <div pRipple class="operate-list" (click)="showSetThemeFlag = true"
                    style="border-bottom: 1px solid #DEE1E6;">
                    <i class="pi pi-cog p-mr-3 p-ml-2" style="font-size:20px;"></i>
                    <div>主题设置</div>
                    <i class="pi pi-angle-right" style="margin-left:35px"></i>
                </div>
                <div pRipple class="operate-list" (click)="updateUserPwd()"
                    style="border-bottom: 1px solid #DEE1E6;">
                    <i class="pi pi-tags p-mr-3 p-ml-2" style="font-size:20px;"></i>
                    <div>修改密码</div>
                    <i class="pi pi-angle-right" style="margin-left:35px"></i>
                </div>
                <div pRipple class="operate-list" (click)="logout()">
                    <i class="pi pi-power-off p-mr-3 p-ml-2" style="font-size:20px;"></i>
                    <div>退出系统</div>
                    <i class="pi pi-angle-right" style="margin-left:35px"></i>
                </div>
            </div>
        </div>
        <div #userOperate class="side-user" (click)="showUserInfo($event)" *ngIf="this.headerSetType == 'hidden' && menuWidth == '64px'">
            <span>
                <img src="assets/file/image/user.png" alt="mirage-layout" style="width:38px;height:38px;">
            </span>
            <div *ngIf="toggleUserInfo" [@overlayMenuAnimation]="'visible'"
                style="position:absolute;top:100%;left:5px;z-index: 200;border-radius: 3px;box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);">
                <div pRipple class="operate-list" (click)="queryUserInfo()"
                    style="border-bottom: 1px solid #DEE1E6;width:52px;">
                    <i class="pi pi-user p-mr-3 p-ml-2" style="font-size:20px;"></i>
                </div>
                <div pRipple class="operate-list" (click)="showSetThemeFlag = true"
                    style="border-bottom: 1px solid #DEE1E6;width:52px;">
                    <i class="pi pi-cog p-mr-3 p-ml-2" style="font-size:20px;"></i>
                </div>
                <div pRipple class="operate-list" (click)="updateUserPwd()"
                    style="border-bottom: 1px solid #DEE1E6;width:52px;">
                    <i class="pi pi-tags p-mr-3 p-ml-2" style="font-size:20px;"></i>
                </div>
                <div pRipple class="operate-list" (click)="logout()" style="width:52px;">
                    <i class="pi pi-power-off p-mr-3 p-ml-2" style="font-size:20px;"></i>
                </div>
            </div>
        </div>
        <div class="layout-menu-container" [ngStyle]="{'height': menuHeight}">
            <ng-container *ngIf="menuWidth == '240px'">
                <div *ngFor="let menu of menuList;let i = index;">
                    <div class="menu-item" [ngClass]="{'active-menu-item': menu.toggle}" (click)="toggleMenu(menu)">
                        <div>
                            <span class="p-mr-3">
                                <i [class]="'pi ' + menu.icon"></i>
                            </span>
                            <span>{{menu.menuname}}</span>
                        </div>
                        <div>
                            <i [class]="menu.toggle ? 'pi pi-angle-down': 'pi pi-angle-up' "></i>
                        </div>
                    </div>
                    <ng-container *ngIf="menu['toggle']">
                        <div class="child-menu" [ngClass]="{'active-child-menu': selectMenuId == citem['id']}"
                            (click)="navegateUrl(citem)" *ngFor="let citem of menu['children']">
                            {{citem['menuname']}}
                            <i *ngIf="!favoriteMenuMap[citem['id']]" class="pi pi-star-o"
                                (click)="chooseFavorite(citem, $event)"></i>
                            <i *ngIf="favoriteMenuMap[citem['id']]" class="pi pi-star"
                                (click)="removeChooseFavorite(citem, $event, menu.menuname)"></i>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="menuWidth == '64px'">
                <div pRipple *ngFor="let menu of menuList;let i = index;" class="menu-item"
                    [ngClass]="{'active-menu-item active-min-item': menu.toggle}" (click)="toggleMenu(menu)"
                    (mouseover)="onSubMenuOpen(menu)">
                    <i [class]="'pi ' +  menu['icon']"></i>
                </div>
            </ng-container>
        </div>
        <div class="sidebar-set p-d-flex p-ai-center" *ngIf="this.headerSetType == 'hidden' && menuWidth == '240px' ">
            <i class="pi pi-phone" style="font-size: 21px;margin:0 10px;"></i>
            <i class="pi pi-envelope" style="font-size: 21px;margin:0 10px;"></i>
            <i pRipple class="pi megamenu-icon"
                [ngClass]="{'pi-window-minimize': windowResizeFlag, 'pi-window-maximize': !windowResizeFlag}"
                style="font-size: 21px;margin:0 10px;" (click)="resizeWindow()"></i>
        </div>
        <div #sidebarSet class="sidebar-set p-d-flex p-ai-center" *ngIf="this.headerSetType == 'hidden' && menuWidth == '64px'">
            <span (click)="showBoxInfo($event)">
                <i class="pi pi-inbox" style="font-size: 21px;margin:0 10px;"></i>
            </span>
            <div *ngIf="toggleBoxInfo" [@overlayMenuAnimation]="'visible'"
                style="position:absolute;bottom:100%;left:5px;z-index: 200;border-radius: 3px;box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);">
                <div pRipple class="operate-list" (click)="showContractPhone()"
                    style="border-bottom: 1px solid #DEE1E6;width:52px;">
                    <i class="pi pi-phone" style="font-size: 21px;margin:0 10px;"></i>
                </div>
                <div pRipple class="operate-list"
                    style="border-bottom: 1px solid #DEE1E6;width:52px;">
                    <i class="pi pi-envelope" style="font-size: 21px;margin:0 10px;"></i>
                </div>
                <div pRipple class="operate-list" (click)="resizeWindow()"
                    style="border-bottom: 1px solid #DEE1E6;width:52px;">
                    <i pRipple class="pi megamenu-icon" [ngClass]="{'pi-window-minimize': windowResizeFlag, 'pi-window-maximize': !windowResizeFlag}"
                        style="font-size: 21px;margin:0 10px;"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="layout-main" [style]="{width: 'calc(100vw - '+ menuWidth + ')'}">
        <div class="layout-header" style="position: relative;" *ngIf="headerSetType == 'show'">
            <div class="p-d-flex p-ai-center">
                <div
                    style="float:left;display: flex;justify-content:center;height:100%;align-items : center;margin-right:50px;margin-left: 2em;">
                    <i class="pi pi-bars" (click)="toggleSilderMenu()"></i>
                </div>
                <!-- 联系方式 -->
                <div class="p-d-flex p-ai-center">
                    <img class="p-mr-2" src="assets/icon/phone.png" alt="">
                    <div class="p-mr-2" style="color:var(--text-color)">联系电话: <span>{{loginData['mobilePhone']}}</span></div>
                </div>
                <div *ngIf="isShowShipper == true" (click)="showShipperQcode()" class="p-d-flex p-ai-center" style="margin-left: 2em;cursor:pointer;">
                    <img class="p-mr-2" src="assets/icon/qrcode.png" alt="">
                    <div class="p-mr-2">托运方码</div>
                </div>
                <div *ngIf="isShowShipper == true" (click)="showShipperOrderQcode()" class="p-d-flex p-ai-center" style="margin-left: 2em;cursor:pointer;">
                    <img class="p-mr-2" src="assets/icon/qrcode.png" alt="">
                    <div class="p-mr-2">货源码</div>
                </div>
            </div>
            <div style="display: flex;align-items: center;height: 100%;">
                <div class="userinfo p-mr-5">
                    <i class="pi pi-envelope" style="font-size: 21px;"></i>
                </div>
                <div class="userinfo p-mr-5">
                    <i pRipple class="pi megamenu-icon"
                        [ngClass]="{'pi-window-minimize': windowResizeFlag, 'pi-window-maximize': !windowResizeFlag}"
                        style="font-size: 21px;" (click)="resizeWindow()"></i>
                </div>
                <div #userOperate pRipple class="userinfo" style="position:relative;" (click)="showUserInfo($event)">
                    <span>
                        <img src="assets/file/image/user.png" alt="mirage-layout" style="width:38px;height:38px;">
                    </span>
                    <span style="margin-left: 15px;color: var(--text-color);">
                        <h4 style="width:80px;margin:0;font-size:15px;font-weight: 600;overflow: hidden;height:19px;text-overflow: ellipsis;">
                            {{ loginData['realname'] }}
                        </h4>
                        <div style="width:80px;font-size:13px;overflow: hidden;height:19px;text-overflow: ellipsis;margin-left:2px;">
                            {{ loginData['companyName'] }}
                        </div>
                    </span>
                    <div *ngIf="toggleUserInfo" [@overlayMenuAnimation]="'visible'"
                        style="position:absolute;top:100%;left:-60px;z-index: 200;border-radius: 3px;box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);">
                        <div pRipple class="operate-list" (click)="queryUserInfo()"
                            style="border-bottom: 1px solid #DEE1E6;">
                            <i class="pi pi-user p-mr-3 p-ml-2" style="font-size:20px;"></i>
                            <div>个人信息</div>
                            <i class="pi pi-angle-right" style="margin-left:35px"></i>
                        </div>
                        <div pRipple class="operate-list" (click)="showSetThemeFlag = true"
                            style="border-bottom: 1px solid #DEE1E6;">
                            <i class="pi pi-cog p-mr-3 p-ml-2" style="font-size:20px;"></i>
                            <div>主题设置</div>
                            <i class="pi pi-angle-right" style="margin-left:35px"></i>
                        </div>
                        <div pRipple class="operate-list" (click)="updateUserPwd()"
                            style="border-bottom: 1px solid #DEE1E6;">
                            <i class="pi pi-tags p-mr-3 p-ml-2" style="font-size:20px;"></i>
                            <div>修改密码</div>
                            <i class="pi pi-angle-right" style="margin-left:35px"></i>
                        </div>
                        <div pRipple class="operate-list" (click)="logout()">
                            <i class="pi pi-power-off p-mr-3 p-ml-2" style="font-size:20px;"></i>
                            <div>退出系统</div>
                            <i class="pi pi-angle-right" style="margin-left:35px"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 弹出二维码 -->
        <div *ngIf="showCode==true" class="code-container" style="position: absolute;top: 50px;left: 554px;">
            <img src="assets/icon/qcCode1.png" alt="">
            <div style="font-size: 1.1rem;color: var(--primary-color);cursor: pointer;">下载图片</div>
        </div>
        <div class="layout-body" style="position:relative;">
            <ng-container *ngIf="showSubMenu">
                <div #subMneu class="child-menu-position" (mouseleave)="onHideSubmenu()">
                    <div class="child-menu"
                        style="border-bottom:2px solid var(--surface-border);justify-content: start;">
                        <i [class]="'pi ' +  menuItem['icon']"></i>
                        <span class="p-ml-3">{{menuItem['menuname']}}</span>
                    </div>
                    <div class="child-menu" [ngClass]="{'active-child-menu': selectMenuId == citem['id']}"
                        (click)="navegateUrl(citem, menuItem)" *ngFor="let citem of subMenus">
                        {{citem['menuname']}}
                        <i *ngIf="!favoriteMenuMap[citem['id']]" class="pi pi-star-o"
                            (click)="chooseFavorite(citem, $event)"></i>
                        <i *ngIf="favoriteMenuMap[citem['id']]" class="pi pi-star"
                            (click)="removeChooseFavorite(citem, $event, menuItem.menuname)"></i>
                    </div>
                </div>
            </ng-container>
            <div class="tabs">
                <ul id="tabs">
                    <li *ngFor="let item of tabMenu;let i = index;" (click)="handleChange(item)">
                        <a title="{{item.menuname}}" [ngStyle]="{'padding': i===0 ? '0.5em 1.2em':''}"
                            [ngClass]="{'first-a': i === 0, 'active-tab' : item['id'] === selectMenuId, 'normal-tab': item['id'] !== selectMenuId }">
                            {{item.menuname}}
                            <div class="delete-icon" *ngIf="i !== 0" (click)="handleDelete(item, $event)">
                                <i class="pi pi-times" style="font-size: 0.2em;"></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div>
                <div [@routeAnimations]="prepareRoute(outlet)" style="position:relative;">
                    <router-outlet #outlet="outlet"></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- 密码修改开始 -->
<p-dialog header="密码修改" [(visible)]="updatePwdFlag" [modal]="true" [style]="{width: '400px'}" class="edit-commmon-flag"
    [draggable]="true" [resizable]="false">
    <form [formGroup]="validateForm" style="width:100%;padding:24px 16px 0 16px;">
        <div class="p-field p-grid">
            <label for="oldPassword" class="p-col-fixed" style="width:120px">原密码:</label>
            <div class="p-col">
                <input type="password" formControlName="oldPassword" [feedback]="false" pPassword
                    [style]="{width: '100%'}" autocomplete="off" />
            </div>
        </div>
        <div class="p-field p-grid">
            <label for="newPassword" class="p-col-fixed" style="width:120px">新密码:</label>
            <div class="p-col">
                <input type="password" formControlName="newPassword" [feedback]="false" pPassword
                    [style]="{width: '100%'}" autocomplete="off" />
            </div>
        </div>
        <div class="p-field p-grid">
            <label for="newPassword1" class="p-col-fixed" style="width:120px">新密码确认:</label>
            <div class="p-col">
                <input type="password" formControlName="newPassword1" [feedback]="false" pPassword
                    [style]="{width: '100%'}" autocomplete="off" />
            </div>
        </div>
        <!-- <span class="p-field p-input-icon-left" style="margin: 10px 0;">
                <i class="pi pi-key" style="color:black"></i>
                <input [class]="{'ng-dirty-pwd': validateForm.controls['password'].invalid}"
                    aria-describedby="password-help" id="password" class="form-input" placeholder="请输入密码"
                    type="password" formControlName="password" pPassword />
            </span> -->
    </form>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="saveUserPwd()" label="保存" class="p-button-text"></p-button>
        <button pButton pRipple icon="pi pi-times" type="button" label="取消" (click)="updatePwdFlag = false"
            class="p-button-raised p-button-text p-button-plain"></button>
    </ng-template>
</p-dialog>
<p-confirmDialog #confirm key="confirmResetPassword" header="Confirmation" icon="pi pi-exclamation-triangle"
    [style]="{width: '30vw'}" [baseZIndex]="100000">
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-check" label="确认" (click)="confirm.accept()"></button>
        <button type="button" pButton icon="pi pi-times" label="取消" class="p-button-raised p-button-text p-button-plain"
            (click)="confirm.reject()"></button>
    </ng-template>
</p-confirmDialog>
<!-- 全局消息提示 -->
<p-toast position="top-center" key="showMsg"></p-toast>

<!-- 货主 -->
<p-dialog [modal]="true" header="托运方码" [(visible)]="showQCodeFlag" [style]="{width: '460px', textAlign:'center'}">
    <div class="qc">
        <div class="qc-top"></div>
        <div class="qc-name" style="font-weight: 500;font-size: large;color: #fff;">
            {{loginData['companyName']}}
        </div>
        <div class="qc-body-container">
            <div class="qc-body">
                <div
                    style="background: #FFFFFF; border: 8px solid #abc3ff;border-radius: 8px;display: flex;flex-direction: column;align-items: center;">
                    <div class="qc-type">
                        <div class="qc-type-text">
                            托运方码
                        </div>
                    </div>
                    <ngx-qrcode style="border-radius: 8px;" [elementType]="elementType" [value]="qCodeValue"
                        [errorCorrectionLevel]="correctionLevel" [width]="200">
                    </ngx-qrcode>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="下载图片" class="p-button-sm p-mr-2" [style]="{'width':'100px'}"
            (click)="downloadQCdoe()"></button>
        <button pButton pRipple type="button" label="取消" class="p-button-sm p-button-outlined"
            [style]="{'width':'100px'}" (click)="showQCodeFlag = false"></button>
    </ng-template>
</p-dialog>

<!-- 货源 -->
<p-dialog [modal]="true" header="货源码" [(visible)]="showBulkOrderQCodeFlag" [style]="{width: '460px', textAlign:'center'}">
    <div class="qc">
        <div class="qc-top"></div>
        <div class="qc-name" style="font-weight: 500;font-size: large;color: #fff;">
            {{loginData['companyName']}}
        </div>
        <div class="qc-body-container">
            <div class="qc-body">
                <div
                    style="background: #FFFFFF; border: 8px solid #abc3ff;border-radius: 8px;display: flex;flex-direction: column;align-items: center;">
                    <div class="qc-type">
                        <div class="qc-type-text">
                            货源码
                        </div>
                    </div>
                    <ngx-qrcode style="border-radius: 8px;" [elementType]="elementType" [value]="orderqCodeValue"
                        [errorCorrectionLevel]="correctionLevel" [width]="200">
                    </ngx-qrcode>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="下载图片" class="p-button-sm p-mr-2" [style]="{'width':'100px'}"
            (click)="downloadQCdoe()"></button>
        <button pButton pRipple type="button" label="取消" class="p-button-sm p-button-outlined"
            [style]="{'width':'100px'}" (click)="showBulkOrderQCodeFlag = false"></button>
    </ng-template>
</p-dialog>

<p-sidebar [(visible)]="showSetThemeFlag" [transitionOptions]="'0ms'" position="right" [style]="{width: '350px'}">
    <div>
        <h4 style="font-size:18px">调节字体</h4>
        <div style="padding-left: 15px">
            <span class="p-mr-2">小</span>
            <span *ngFor="let item of sizeList" class="p-mr-2">
                <i class="pi pi-circle-on normal-size" (click)="chooseSize(item)"
                    [ngClass]="{'choose-size': item.toggle}"></i>
            </span>
            <span class="p-mr-2">大</span>
        </div>
    </div>
    <div>
        <h4 style="font-size:18px">主题设置</h4>
        <div style="padding-left: 15px">
            <span class="p-cursor p-mr-2" (click)="changeTheme('dark')"
                [ngClass]="{'choose-size': themeType == 'dark' }">静夜空</span>
            <span class="p-cursor p-mr-2" (click)="changeTheme('light')"
                [ngClass]="{'choose-size': themeType == 'light' }">火烧云</span>
            <span class="p-cursor p-mr-2" (click)="changeTheme('light-blue')"
                [ngClass]="{'choose-size': themeType == 'light-blue' }">一线天</span>
        </div>
    </div>
    <div>
        <h4 style="font-size:18px">数据格式</h4>
        <div style="padding-left: 15px">
            <span class="p-cursor p-mr-2" (click)="changeDataType('table')"
                [ngClass]="{'choose-size': dataType == 'table' }">列表式</span>
            <span class="p-cursor p-mr-2" (click)="changeDataType('card')"
                [ngClass]="{'choose-size': dataType == 'card' }">卡片式</span>
        </div>
    </div>
    <div>
        <h4 style="font-size:18px">头部设置</h4>
        <div style="padding-left: 15px">
            <span class="p-cursor p-mr-2" (click)="changeHeaderSet('show')"
                [ngClass]="{'choose-size': headerSetType == 'show' }">显示</span>
            <span class="p-cursor p-mr-2" (click)="changeHeaderSet('hidden')"
                [ngClass]="{'choose-size': headerSetType == 'hidden' }">隐藏</span>
        </div>
    </div>
    <div>
        <h4 style="font-size:18px">默认行数设置</h4>
        <div style="padding-left: 15px">
            <span class="p-cursor p-mr-2">
                <span class="p-cursor p-mr-2" (click)="changeDefaultRowsSet(10)"
                    [ngClass]="{'choose-size': defaultRows == 10 }">10</span>
                <span class="p-cursor p-mr-2" (click)="changeDefaultRowsSet(30)"
                    [ngClass]="{'choose-size': defaultRows == 30 }">30</span>
                <span class="p-cursor p-mr-2" (click)="changeDefaultRowsSet(50)"
                    [ngClass]="{'choose-size': defaultRows == 50 }">50</span>
                <span class="p-cursor p-mr-2" (click)="changeDefaultRowsSet(100)"
                    [ngClass]="{'choose-size': defaultRows == 100 }">100</span>
            </span>
        </div>
    </div>
    <div>
        <h4 style="font-size:18px">默认查询区间</h4>
        <div style="padding-left: 15px">
            <span class="p-cursor p-mr-2">
                <span class="p-cursor p-mr-2" (click)="changeDefaultSearchRange(15)"
                    [ngClass]="{'choose-size': searchRange == 15 }">15</span>
                <span class="p-cursor p-mr-2" (click)="changeDefaultSearchRange(30)"
                    [ngClass]="{'choose-size': searchRange == 30 }">30</span>
                <span class="p-cursor p-mr-2" (click)="changeDefaultSearchRange(60)"
                    [ngClass]="{'choose-size': searchRange == 60 }">60</span>
                <span class="p-cursor p-mr-2" (click)="changeDefaultSearchRange(90)"
                    [ngClass]="{'choose-size': searchRange == 90 }">90</span>
            </span>
        </div>
    </div>
</p-sidebar>

<p-sidebar [(visible)]="personalDetailsFlag" [transitionOptions]="'0ms'" position="right" [style]="{width: '350px'}">
    <h4 style="font-size:18px">个人资料</h4>
    <div class="p-field p-grid">
        <label for="firstname3" class="p-col-fixed" style="width:100px">登录账号：</label>
        <div class="p-col">
            <label for="firstname3" class="p-col-fixed" style="width:100px">{{ loginData['username'] }}</label>
        </div>
    </div>
    <div class="p-field p-grid">
        <label for="lastname3" class="p-col-fixed" style="width:100px">用户名称：</label>
        <div class="p-col">
            <label for="lastname3" class="p-col-fixed" style="width:100px">{{ loginData['realname'] }}</label>
        </div>
    </div>
    <div class="p-field p-grid">
        <label for="lastname3" class="p-col-fixed" style="width:100px">公司名称：</label>
        <div class="p-col">
            <label for="lastname3" class="p-col-fixed" style="width:100px">{{ loginData['companyName'] }}</label>
        </div>
    </div>
</p-sidebar>