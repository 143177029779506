import { format, parse } from 'date-fns';
import { NavigationEnd } from '@angular/router';
import { DomHandler } from 'primeng/dom';
import { Observable } from 'rxjs';

export class BaseCommpont {

    dialog_width_small = "400px";                                       // 表单一列宽度
    dialog_width_middle = '750px';                                      // 表单两列宽度
    dialog_width_middler = '1050px';                                    // 表单三列宽度
    dialog_width_large = '1250px';                                      // 表单四列宽度
    table_edit_width = '150px';                                         // 表格编辑部分固定宽度
    search_panel_width = '280px';                                       // 查询框固定宽度
    
    dialogBlock = false;                                                // 弹窗block
    contentHeight = '';
    tableHeight = '200px';

    searchDropDown: { [key: string]: [] } = {};                         // 公用初始化查询下拉列表
    editDialogFlag = false;                                             // 默认编辑弹窗
    listOfData: any[] = [];                                             // 表格数据json
    selectedItem = [];                                                  // 当前选中的数据
    tempId = null;                                                      // 默认编辑数据暂存id
    totalNum = 0;                                                       // 表格总条数
    defaultRows = 20;                                                   // 默认列表总条数
    defaultRowsPerPage = [10,30,50,100];                                   // 默认下拉列表
    currentEvent = {};                                                  // 当前分页数据
    svrType = 'success';                                                // 接口返回状态类型
    formToken = '';                                                     // 表单formToken
    formId = '';                                                        // 表单formId

    changeTable = true;                                                 // 查询列表显示方式，true: 普通表格，false: 块状表格

    loading = false;

    // 编辑调整列
    editColumnDialog = false;                                           // 调整列弹窗
    editColumnKeys = [];                                                // 调整列键值
    editColumnMsg = '当前列表有更新，请确认！';                           // 更新调整列提示

    modalTitle = '';
    // loadingBlock = false;        // 加载中，true为开启
    // loadingText = '加载中';      // 加载中提示文字

    // 
    loadingNum = 0;                                                     // 列表加载数量（联动触发器）
    emptyData = true;                                                   // 空数据状态切换（联动触发器）

    operatePageButtons = [];                                            // 左上角操作数据
    mapOfCheckedId = [];
    hideSearchValue = [];  //馒头数组
    showEsSearch = false;                                             // 是否显示高级搜索（联动触发器）

    specialBlock: RegExp = /^[^<>*!/#?]+$/;

    moneyRegExp = 'money';

    public msgObser: Observable<any>;                                   // 公共消息

    checkFormItem(form, controlName) {
        return form.get(controlName).invalid &&
                (form.get(controlName).dirty || form.get(controlName).touched);
    }

    clearFilter(filterParam: {}) {
        for (const key in filterParam) {
            filterParam[key] = '';
        }
    }

    // 查询key是否隐藏
    keyExsit(keys, key): boolean {
        let flag = false;
        keys.forEach(e => {
            if (e['key'] == key && e['show'] ) {
                flag = true;
            }
        });
        return flag;
    }

    showMessage(message, messageDetail?: string, messageType?: string, key?: string) {
        if (!this.msgObser ) {
            this.msgObser = message.get('msgObser');
        }
        message.set('msgSub', {
            key: key,
            messageType: messageType,
            messageDetail: messageDetail
        });
        this.msgObser.subscribe(() => {});
    }

    setCreateTime(createTime: number | Date, layout?: string): string {
        if (createTime && createTime !=null && createTime!=0) {
            let time = '';
            try { time = format(createTime, layout ? layout : 'yyyy-MM-dd HH:mm:ss') } catch(e){
                console.log(e)
                return '';
            }
            return time;
        } else {
            return '';
        }
    }

    setCreateTime2(createTime:Date, layout?: string): string {
        if (createTime && createTime !=null) {
            let time = '';
            try { time = format(createTime, layout ? layout : 'yyyy-MM-dd HH:mm:ss') } catch(e){
                console.log(e)
                return '';
            }
            return time;
        } else {
            return '';
        }
    }

    setCreateDay(createTime: number | Date, layout?: string): string {
        debugger
        if (createTime && createTime !=null && createTime!=0) {
            let time = '';
            try { time = format(createTime, layout ? layout : 'yyyy-MM-dd') } catch(e){
                return '';
            }
            return time;
        }
    }

    setScroll(router?, url?, el?) {
        router.events.subscribe((data) => {
            if (data instanceof NavigationEnd) {
                if (url === data.url.substr(1)) {
                    const marginLeft = DomHandler.findSingle(el.nativeElement, '.p-datatable-scrollable-header-box').style.marginLeft;
                    if (marginLeft === '0px') {
                        return;
                    }
                    const pTbody = DomHandler.findSingle(el.nativeElement, '.p-datatable-scrollable-body');
                    pTbody.scrollTo({
                        left: (marginLeft + '').substr(1, (marginLeft + '').length - 3)
                    })
                }
            }
        });
    }

    reloadScroll(el): void {
        const pTbody = DomHandler.findSingle(el.nativeElement, '.p-datatable-scrollable-body');
        if (pTbody) {
            pTbody.scrollTo({
                left: 0
            })
        }
    }

    getLocalDropdown( obj, hasAll?): any[] {
        let tempList = [];
        if(hasAll){
            tempList.push({ value: '', label: '全部' });
        }
        for (const key in obj) {
            tempList.push({ value: key, label: obj[key] });
        }
        return tempList;
    }

    getLocalDropNull( obj, hasAll?): any[] {
        let tempList = [];
        if(hasAll){
            tempList.push({ value: '', label: '不选' });
        }
        for (const key in obj) {
            tempList.push({ value: key, label: obj[key] });
        }
        return tempList;
    }

    initSearch(obj, searchForm: any, initDropList, initValue: any[], initSeach?: boolean, initDateNum?): void {
        initValue.forEach(i => {
            let tempList = [];
            for (const key in obj[i]) {
                tempList.push({ value: key, label: obj[i][key] });
            }
            initDropList[i] = [{ value: '', label: '全部' }, ...tempList];
        });
        if (initSeach) {
            let patchValue = {};
            if (searchForm.get('startTime')) {
                let searchRangeDays = 15;
                if (initDateNum) {
                    searchRangeDays = initDateNum;
                } else {
                    const searchRange = obj.storage.get('search-range');
                    if (searchRange) {
                        searchRangeDays = searchRange;
                    }
                }
                patchValue['startTime'] = this.setCreateTime(new Date().getTime() - (searchRangeDays) * 24 * 60 * 60 * 1000, 'yyyy-MM-dd');
            }
            if (searchForm.get('endTime')) {
                patchValue['endTime'] = this.setCreateTime(new Date(), 'yyyy-MM-dd')
            }
            if (searchForm.get('start_time_2')) {
                patchValue['start_time_2'] = this.setCreateTime(new Date().getTime() - 15 * 24 * 60 * 60 * 1000, 'yyyy-MM-dd');
            }
            if (searchForm.get('end_time_2')) {
                patchValue['end_time_2'] = this.setCreateTime(new Date(), 'yyyy-MM-dd')
            }
            initValue.forEach(i => {
                patchValue[i] = '';
            })
            searchForm.patchValue(patchValue);
        }
    }

    saveForRecord(that:any, flag?:boolean, reloadData? ) {
        that.dialogBlock = false;
        that.editColumnDialog = false;
        that.storage.setObject(that.router.url, {
            updateFlag: that['updateColumnFlag'],
            chcheKeys: that.keys
        });
        if (!flag) {
            this.reloadScroll(that.el);
        }
        if (reloadData) {
            reloadData();
        }
    }

    initCurrentPage(that:any) {
        // 初始化列表格式
        const dataType = that.storage.get('data-type') || 'table';
        if (dataType == 'table') {
            that.changeTable = true;
        } else {
            that.changeTable = false;
        }

        that.defaultRows = Number(that.storage.get('table-rows-type') || 50);

        // 初始化列表key
        const storageChche = that.storage.getObject(that.router.url);
        if (storageChche) { //
            if (that.updateColumnFlag === storageChche['updateFlag']) {
                that.keys = storageChche['chcheKeys'];
                return false;
            } else {
                this.saveForRecord(that, true);
                return true;
            }
        } else {
            return false;
        }
    }

    getCurrentKeyLength(keys) {
        let count = 0;
        keys.forEach(e => {
            if (e.show) {
                count++;
            }
        });
        return count;
    }

    // 下载文件
    downFile(fileUrl: string, fileName: string) {
        let a = document.createElement("a");
        a.href = fileUrl;
        a.download = fileName;
        if (fileName.endsWith('.pdf')) {
            a.target = '_blank';
        }
        a.click();
        a.remove();
    }

    setOperateButtons(operate) {
        return [
            {
                icon: 'pi pi-share-alt',
                command: () => {
                    operate && operate(1);
                }
            },
            {
                icon: 'pi pi-external-link',
                command: () => {
                    operate && operate(2);
                }
            },
            {
                icon: 'pi pi-sort-alt',
                command: () => {
                    operate && operate(3);
                }
            },
            {
                icon: 'pi pi-cog',
                command: () => {
                    operate && operate(4);
                }
            }
        ]
    }

 // 查询列表合计
 getTotalRecord(keys:any, totalData: {}) {
    let totalRecord = [];
    keys.forEach((e, i) => {
        if (i == 0 && !totalData[e['key']]) {
            totalRecord.push({ label: '合计', key: e['key'], show: e['show'], fix: e['fix'] });
        } else {
            totalRecord.push({ label: totalData[e['key']] , key: e['key'], show: e['show'], fix: e['fix']});
        }
    });
    let totalRecords = [];
    totalRecord.forEach((e, i) => {

        let a= e['label'];
        if (typeof(a) == 'undefined')  {
            totalRecords.push({ label: '', key: e['key'], show: e['show'], fix: e['fix']});
        }else{
            totalRecords.push({ label: e['label'], key: e['key'], show: e['show'], fix: e['fix']});

        }
    });
    return totalRecords;
}

    //获取年龄
    getAge(birthDate) {
        if(birthDate=='' || birthDate==null || birthDate==undefined || birthDate==0){
            return 0;
        }
        try {
            birthDate = this.setCreateTime(birthDate*1000,'yyyy-MM-dd');    
        } catch (error) {
            return 0;   
        }
        var returnAge,
            strBirthdayArr = birthDate.split("-"),
            birthYear = strBirthdayArr[0],
            birthMonth = strBirthdayArr[1],
            birthDay = strBirthdayArr[2],
            d = new Date(),
            nowYear = d.getFullYear(),
            nowMonth = d.getMonth() + 1,
            nowDay = d.getDate();
        if (nowYear == birthYear) {
            returnAge = 0;//同年 则为0周岁
        }
        else {
            var ageDiff = nowYear - birthYear; //年之差
            if (ageDiff > 0) {
                if (nowMonth == birthMonth) {
                    var dayDiff = nowDay - birthDay;//日之差
                    if (dayDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                } else {
                    var monthDiff = nowMonth - birthMonth;//月之差
                    if (monthDiff < 0) {
                        returnAge = ageDiff - 1;
                    }
                    else {
                        returnAge = ageDiff;
                    }
                }
            } else {
                returnAge = 0;//返回-1 表示出生日期输入错误 晚于今天
            }
        }
        return returnAge;//返回周岁年龄
    }

    //获取距今多少天(int时间戳)
    timeBeOverdue(endDate){
        if(endDate=='' || endDate==null){
            return '无';
        }
        let startTime = endDate*1000;
        let dateTime = new Date().getTime() 

        var nDays = ((startTime - dateTime)/1000/3600/24);
        if(nDays== 0){
            return '已过期';
        }
        if(nDays<0){
            return '已过期'+Math.round(Math.abs(nDays))+'天';
        }

        var minute = 1000 * 60,
        hour = minute * 60,
        day = hour * 24,
        halfamonth = day * 15,
        month = day * 30,
        year = month * 12,
        result = '',
        _year = (startTime - dateTime) / year,
        _month = (startTime - dateTime) / month,
        _week = (startTime - dateTime) / (7 * day),
        _day = (startTime - dateTime) / day;

        if (_year >= 1) result = _year.toFixed(1) + "年";
        else if (_month >= 1) result = _month.toFixed(1) + "个月";
        else if (_week >= 1) result = Math.round(_week) + "周";
        else result = Math.round(_day) +"天";
        return result;    
    }


    //获取距今多少天
    getDateByDays(endDate){
        if(endDate=='' || endDate==null){
            return '无';
        }
        try {
            endDate = this.setCreateTime(new Date(endDate),'yyyy-MM-dd');    
        } catch (error) {
            return '无';
        }
        if(endDate=='9999-12-31'){
            return '长期';
        }
        var time1 = Date.parse(endDate);
        var time2 = Date.parse(new Date()+'');
        var nDays = parseInt(((time1 - time2)/1000/3600/24)+'');
        if(nDays==0){
            return '已过期';
        }
        if(nDays<0){
            return '已过期'+Math.abs(nDays)+'天';
        }
        var minute = 1000 * 60,
        hour = minute * 60,
        day = hour * 24,
        halfamonth = day * 15,
        month = day * 30,
        year = month * 12,
        result = '',
        _year = (time1 - time2) / year,
        _month = (time1 - time2) / month,
        _week = (time1 - time2) / (7 * day),
        _day = (time1 - time2) / day;

        if (_year >= 1) result = parseInt(_year+'') + "年";
        else if (_month >= 1) result = parseInt(_month+'') + "个月";
        else if (_week >= 1) result = parseInt(_week+'') + "周";
        else result = parseInt(_day+'')+"天";
        return result;    
    }
    
    //获取过期天数
    getExpireDays(endDate,type,day){
        var days = 30;        
        if(day!='' && day!=null){
            days = day;
        }
        if(endDate=='' || endDate==null || endDate==0){
            return 0;
        }
        try {
            endDate = this.setCreateTime(endDate*1000,'yyyy-MM-dd');    
        } catch (error) {
            return 0;
        }
        var time1 = Date.parse(endDate);
        var time2 = Date.parse(new Date()+'');
        var nDays = parseInt(((time1 - time2)/1000/3600/24)+'');
        if(nDays>=0){
            nDays = nDays+1;
            if(nDays<=days){
                return nDays;
            }else {
                return 0;
            }
        }else {
            if(type==1){
                return Math.abs(nDays)+1;
            }else {
                return nDays+1;
            }
        }
    }

    //判断定位时间
    dateDiff(hisTime) {
        if(!hisTime || hisTime==0){
          return '';
        }
        const now = new Date().getTime()
        const diffValue = now - hisTime;
        let   result = ""
        const   minute = 1000 * 60
        const   hour = minute * 60
        const   day = hour * 24
        const   halfamonth = day * 15
        const   month = day * 30
        const   year = month * 12
    
        const    _year = diffValue / year
        const    _month = diffValue / month
        const    _week = diffValue / (7 * day)
        const   _day = diffValue / day
        const    _hour = diffValue / hour
        const    _min = diffValue / minute
        if (_year >= 1) result = parseInt(_year+'') + "年前"
        else if (_month >= 1) result = parseInt(_month+'') + "个月前"
        else if (_week >= 1) result = parseInt(_week+'') + "周前"
        else if (_day >= 1) result = parseInt(_day+'') + "天前"
        else if (_hour >= 1) result = parseInt(_hour+'') + "小时前"
        else if (_min >= 1) result = parseInt(_min+'') + "分钟前"
        else result = "刚刚"
        return result;
      }
    
      arabiaToChinese(n) {
        if(!n){
            return '零元整';
        }
        var unit = "仟佰拾亿仟佰拾万仟佰拾元角分", str = "";
        n += "00";
        var a = n.indexOf('-');
        if (a != -1) {
            n = n.substring(1);
            var indexpoint = n.indexOf('.');  // 如果是小数，截取小数点前面的位数
            if (indexpoint >= 0) {
                n = n.substring(0, indexpoint) + n.substr(indexpoint + 1, 2);   // 若为小数，截取需要使用的unit单位
            }
            unit = unit.substr(unit.length - n.length);  // 若为整数，截取需要使用的unit单位
            for (var i = 0; i < n.length; i++) {
                str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);  //遍历转化为大写的数字
            }
            return "负数" + str.replace(/零(仟|佰|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万/g, "$1").replace(/^元零?|零分/g, "").replace(/元$/g, "元整").replace(/角$/g, "角整"); // 替换掉数字里面的零字符，得到结果
        } else {
            var indexpoint = n.indexOf('.');  // 如果是小数，截取小数点前面的位数
            if (indexpoint >= 0) {
                n = n.substring(0, indexpoint) + n.substr(indexpoint + 1, 2);   // 若为小数，截取需要使用的unit单位
            }
            unit = unit.substr(unit.length - n.length);  // 若为整数，截取需要使用的unit单位
            for (var i = 0; i < n.length; i++) {
                str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);  //遍历转化为大写的数字
            }
            return str.replace(/零(仟|佰|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万/g, "$1").replace(/^元零?|零分/g, "").replace(/元$/g, "元整").replace(/角$/g, "角整"); // 替换掉数字里面的零字符，得到结果
        }
    }

    //金额保留两位小数
    getFormatNumber(val) {
        if (val) {
            return Number(Number(val / 100).toFixed(2));
        } else {
            return 0;
        }
    }

    //金额*100
    getFormatNumberMul(val) {
        if (val) {
            return Number(Number(val * 100).toFixed(0));
        } else {
            return 0;
        }
    }

    //保留两位小数 val参数，num保留位数
    getFormatNumberToFixed(val,num?) {
        if (val) {
            if(num && num>0){
                return Number(Number(val).toFixed(num));
            }else {
                return Number(Number(val).toFixed(2));
            }
        } else {
            return 0;
        }
    }

    //运费*100 保留两位
    getFormatNumberMultiply(val) {
        if (val) {
            return Number(Number(val*100).toFixed(2));
        } else {
            return 0;
        }
    }
}
