import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MessageSub {

    public data = {};

    set(name, obServerble) {
        this.data[name] = obServerble;
    }

    get(name) {
        return this.data[name];
    }

    constructor(){}
}