import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './main/layout/layout.component';
import { RoutingModule } from './routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { BlockUIModule } from 'primeng/blockui';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { SpeedDialModule } from './../components/speeddial/speeddial.component';

@NgModule({
  declarations: [
    LayoutComponent
  ],
  providers: [
    ConfirmationService
  ],
  imports: [
        DialogModule, SidebarModule, BlockUIModule,
        FormsModule, ReactiveFormsModule, HttpClientModule,
        CommonModule, RoutingModule, ToastModule,
        ButtonModule, PasswordModule,ConfirmDialogModule,NgxQRCodeModule,
        SpeedDialModule
  ],
  exports: [RoutingModule, BlockUIModule, ToastModule]
})
export class ComponentsModule { }
